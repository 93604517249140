<template>
  <div>
    <headPage />
    <div class="box1">
      <img
        class="bg1"
        style="display: block; width: 100%"
        src="../assets/image/image26.png"
      />
      <div class="mask"></div>
      <div class="info1">
        <p class="p1">MH享家</p>
        <p class="p2">舒适美好生活缔造师</p>
        <p class="p3">
          <span>五恒系统</span><span>智能灯光</span><span>影音娱乐</span
          ><span>智慧控制</span>
        </p>
      </div>
    </div>
    <div class="bgbox">
      <div id="warp">
        <!-- 你选择的是：
      <mark v-for="(item, index) in arr">
        <span>{{ item.name }}</span
        ><a
          style="color: red"
          href="javascript:;"
          @click="remove(index, item.id)"
          >X</a
        >
      </mark> -->
        <!-- <span v-for="(item, index) in arr">{{ item.name }}</span> -->
        <div>
          <ul>
            <li v-for="(item, index) in houseData">
              <span>{{ item.title }}:</span>
              <a
                href="javascript:;"
                v-for="(option, i) in item.list"
                v-bind:class="item.index == i ? 'active' : ''"
                @click="listBundle(option, index, i)"
                >{{ option }}
              </a>
            </li>
          </ul>
          <div class="box2">
            <div v-for="(item, index) in caseList">
              <div class="case" v-if="item.show">
                <img
                  style="cursor: pointer"
                  @click="jump('/caseDetail?caseId=' + item.caseId)"
                  class="img3"
                  :src="item.img"
                  alt=""
                />
                <div>{{ item.name }}</div>
                <a @click="jump('/caseDetail?caseId=' + item.caseId)"
                  >了解详情</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import caseData from "../../public/caseData.json";

import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";

export default {
  data() {
    return {
      caseList: caseData.caseList,
      type: "",
      area: "",
      houseData: [
        {
          title: "类型",
          value: "type",
          index: 0,
          list: ["不限", "家装", "工装"],
        },
        {
          title: "面积",
          value: "area",
          index: 0,
          list: ["不限", "500m²以下", "500-1000m²", "1000m²以上"],
        },
      ],
    };
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    listBundle(option, index, i) {
      if (index == 0) {
        this.type = this.houseData[index].list[i];
      } else {
        this.area = this.houseData[index].list[i];
      }
      this.houseData[index].index = i;
      this.caseList.forEach((item) => {
        //循环被筛选数组
        var filter = false; //重置被循环数据是否已筛选
        for (var obj of this.houseData) {
          //循环筛选数组查询条件
          if (obj.index > 0) {
            //查询该筛选条件大于0 则该筛选条件启用
            //判断已选中 obj.value筛选条件的key  对应被筛选数组的属性   当该属性值与筛选条件值相等则显示
            if (obj.list[obj.index] == item[obj.value] && !filter) {
              //判断选中的
              item.show = true;
            } else {
              //该条数据已被筛选 在下一轮条件筛选中清除
              filter = true;
              item.show = false;
            }
          } else {
            if (!filter) {
              item.show = true;
            }
          }
        }
      });
      //   this.area.forEach((item) => {
      //     if (this.type == "") {
      //       if (this.area == "") {
      //         item.show = true;
      //       } else {
      //         if (item.area == this.area) {
      //           item.show = true;
      //         } else {
      //           item.show = false;
      //         }
      //       }
      //     } else {
      //       if (this.area == "") {
      //         if (item.type == this.type) {
      //           item.show = true;
      //         } else {
      //           item.show = false;
      //         }
      //       } else {
      //         if (item.area == this.area && item.type == this.type) {
      //           item.show = true;
      //         } else {
      //           item.show = false;
      //         }
      //       }
      //     }
      //   });
      this.$forceUpdate();
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.bgbox {
  height: auto;
  width: 100%;
}
.case {
  margin: 40px 40px 40px 60px;
  a {
    cursor: pointer;
  }
}
.img3 {
  width: 350px;
}
.box2 {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 90%;
  margin-left: 80px;
  a {
    text-decoration: underline;
  }
}
#warp {
  font-family: "SourceHanSansCN-Medium";
  text-align: left;
  font-size: 20px;
  span {
    margin-right: 20px;
  }
}

mark {
  width: 100%;
  height: 30px;
  border: 1px solid pink;
}
ul {
  margin-left: 57px;
}
li {
  overflow: hidden;
  list-style: none;
  margin: 10px 0 0 0;
  width: 97%;
  height: 70;
  margin-left: 40px;
}

a {
  display: inline-block;
  margin: 10px 20px 0 0;
  text-decoration: none;
  color: #000;
}

.active {
  background-color: #d1a937;
  border-radius: 10px;
  text-align: center;
}
.box1 {
  position: relative;
}
.mask {
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #1f1e1e 130%);
  color: #ffffff;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

.info1 {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  .p1 {
    color: #d0ac32;
    font-size: 60px;
    font-family: "SourceHanSansCN-Medium";
  }
  .p2 {
    color: #fff;
    font-size: 65px;
    font-family: "SourceHanSansCN-Blod";
  }
  .p3 {
    margin-top: 15px;
    color: #fff;
    font-family: "SourceHanSansCN-Light";
    font-size: 25px;
    span {
      margin-right: 20px;
    }
  }
}
</style>